<template>
  <div>
    <div>
      <headerTop></headerTop>
    </div>
    <div>
      <headerMin></headerMin>
    </div>
    <div>
      <div class="all_comment">
        <div class="dc_indexes">
          <span @click="$router.push('/')">首页 ></span>
          <span v-if="type == 1" @click="$router.push('/expert/index')">线上问诊 ></span>
          <span v-else @click="$router.push('/doctor/index')">线上问诊 ></span>
          <span>{{cate_type}} ></span>
          <span>{{doctor_name}}</span>
        </div>
        <div class="title">
          <div class="pageTop">
            <span>{{ current_page }}</span>
            / {{ last_page }}
            <!-- <img @click="changePage('pre')" src="@/assets/image/Listofcommodities_Left_icon@2x.png" alt />
            <img @click="changePage('next')" src="@/assets/image/Listofcommodities_right_icon@2x.png" alt />-->
          </div>
        </div>

        <div class="warp_comment">
          <div class="dc_assess">
            <div>
              <div class="a_list" v-for="(v, i) in commentList" :key="i">
                <div class="a_user">
                  <img :src="v.user_info.avatar" alt />
                  <p class="one">{{ v.user_info.user_nickname }}</p>
                </div>
                <div class="a_txt plbox" >
                  <p class="two p1 ">{{ v.content }}</p>
                  <p class="p2">
                    {{ v.add_time }}
                    <span></span>
                    问诊方式：{{ v.chat_type == 0 ? '图文问诊' : '视频问诊' }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="page" v-if="showPage">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="15"
              current-change="currentPageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="bg1">
      <publicBottom></publicBottom>
    </div>
  </div>
</template>

<script>
import headerTop from "@/components/public/public_headerTop.vue";
import headerMin from "@/components/index/index_header_min";
import publicBottom from "@/components/public/public_bottom.vue";
import { getCommentList } from "@/request/user";

export default {
  components: {
    headerTop,
    headerMin,
    publicBottom
  },
  data() {
    return {
      commentList: [],
      total: 0,
      showPage: false,
      last_page: 1,
      current_page: 1,
      type: 2
    };
  },
  created() {
    this.cate_type = this.$route.query.cate_type;
    this.doctor_name = this.$route.query.doctor_name;
    this.uid = this.$route.query.uid;
    this.type = this.$route.query.type; // 1代表由专家跳转过来。
    this.getPageCommentList(1);
  },
  methods: {
    getPageCommentList(n) {
      getCommentList({
        doctor_uid: this.uid,
        page: n
      }).then(res => {
        if (res.code == 1) {
          this.showPage = res.data.last_page != 1;
          this.total = res.data.total;
          this.commentList = res.data.data;
          this.last_page = res.data.last_page;
        }
      });
    },
    currentPageChange(n) {
      this.current_page = n;
      this.getPageCommentList(n);
    }
  }
};
</script>

<style lang="less" scope>
@import "./doctor.less";
.a_list{
	padding: 10px 0;
}
.plbox{
	background-color: #EAEAEA;
	padding: 5px;
	border-radius: 10px;
	height:100px;
}
</style>